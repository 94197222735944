<template>
  <div
    v-if="props.typeId && $store.getters['requests/getRequestsType'](props.typeId)"
    class="tw-flex tw-items-center tw-h-full"
  >
    <el-tooltip
      :content="$store.getters['requests/getRequestsType'](props.typeId).nameTooltip"
      placement="right"
      :disabled="props.client?.lang !== 'ua'"
    >
      <div class="tw-flex tw-items-center tw-h-full">
        <component
          :is="$store.getters['requests/getRequestsType'](props.typeId).icon"
          :class="`color-${$store.getters['requests/getRequestsType'](props.typeId).color}`"
          class="icon tw-mr-2"
        />
        <div
          class="tw-font-semibold"
          :class="{ 'tw-text-danger-500': props.typeId === $store.state.requests.EMERGENCY_TYPE_ID }"
        >
          {{ $store.getters['requests/getRequestsType'](props.typeId).name }}
        </div>
      </div>
    </el-tooltip>
  </div>
</template>

<script setup>
const props = defineProps({
  typeId: {
    type: Number,
    default: null,
  },
  client: {
    type: Object,
    default: null,
  },
});
</script>
